import { createHttpEndpoint } from '../utils'

import type { CreateRecyclingResponse, GetRecycling } from './recycling.types'

/**
 * Create recycling
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/recycling-api/definition#/default/create_recycling}
 */
export const createRecycling = createHttpEndpoint<CreateRecyclingResponse>({
  method: 'POST',
  operationId: 'createRecycling',
  path: '/recycling/api/v1/recyclings',
})

/**
 * Get recycling
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/recycling-api/definition#/default/get_recycling}
 */
export const getRecycling = createHttpEndpoint<GetRecycling>({
  method: 'GET',
  operationId: 'getRecycling',
  path: '/recycling/api/v1/recyclings/:orderId',
})
